<template>
  <q-item clickable @click="notificationClick" :class="notification.seen ? '' : 'bg-info'">
    <q-item-section v-if="notification.actionable_type === 'Module' || notification.actionable_type === 'DueDate'" avatar style="width: 125px">
      <q-img :src="thumbnailPath" />
    </q-item-section>
    <q-item-section v-else avatar style="width: 125px" class="row items-center">
      <q-icon rounded :name="icon" size="50px"/>
    </q-item-section>
    <q-item-section v-if="customMessage" class="text-caption">{{ customMessage }}</q-item-section>
    <q-item-section v-else class="text-caption">New {{ notification.actionable_type }}</q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'NotificationItem',
  props: {
    notification: {}
  },
  data () {
    return {
      highlightSection: false,
      thumbnailPath: '',
      algoliaResponse: null
    }
  },
  computed: {
    icon () {
      let icon
      switch (this.notification.actionable_type) {
        case 'News':
          icon = 'web'
          break
        case 'Upload':
          icon = 'cloud_upload'
          break
        case 'Announcement':
          icon = 'announcement'
          break
        case 'Message':
          icon = 'chat'
          break
        case 'SubmittedContent':
          icon = 'videocam'
          break
      }
      return icon
    },
    customMessage () {
      if (this.notification.custom_message) return this.notification.custom_message

      if (this.notification.actionable_type === 'Module' || this.notification.actionable_type === 'DueDate') {
        if (this.algoliaResponse) {
          return `You've been assigned '${this.algoliaResponse.title}'`
        } else {
          return `You've been assigned a module`
        }
      }

      return null
    }
  },
  methods: {
    notificationClick () {
      switch (this.notification.actionable_type) {
        case 'Module':
        case 'DueDate':
          this.$router.push({ name: 'module', params: { moduleId: this.notification.actionable_uid } }).catch(() => {})
          break
        case 'Message':
          this.$router.push({ name: 'shoutOuts' })
          break
        case 'SubmittedContent':
          this.$router.push({ name: 'submittedContent' })
          break
        default:
          this.$router.push({ name: 'homePage' }).catch(() => {})
          this.$emit('highlightSection', this.notification.actionable_type.toLowerCase())
          break
      }
    },
    async getAlgoliaInfo () {
      try {
        return (await this.$queryAlgoliaModules(this.notification.actionable_uid)).results[0].hits[0]
      } catch (e) {
        throw e
      }
    }
  },
  async created () {
    if (this.notification.actionable_type === 'Module' || this.notification.actionable_type === 'DueDate') {
      this.algoliaResponse = await this.getAlgoliaInfo()
      this.thumbnailPath = this.algoliaResponse.thumbnail_path
    }
  }
}
</script>

<style lang="stylus" scoped>
  .unread
    background-color #D1EEFC
</style>
