<template>
  <q-list class="q-my-sm">
    <q-item v-for="(item, i) in mainItemsFiltered" :key="i" :to="item.link" tag="a" class="q-mx-md">
      <q-item-section avatar class="side-menu-avatar">
        <q-icon :name="item.icon"/>
      </q-item-section>
      <q-item-section>{{item.title}}</q-item-section>
      <q-item-section side v-if="item.title === 'Required Training' && $store.getters['assignments/unfinished'].length > 0"><q-chip dense :label="$store.getters['assignments/unfinished'].length" /></q-item-section>
      <q-item-section side v-if="item.title === 'Bookmarks' && $store.getters['bookmarks/data'].length > 0"><q-chip dense :label="$store.getters['bookmarks/data'].length" /></q-item-section>
      <q-item-section side v-if="item.title === 'Submitted Content' && $isAllowed(['||', 'site-admin'], true) && $store.getters['submittedContent/count'] > 0"><q-chip dense :label="$store.getters['submittedContent/count']" /></q-item-section>
    </q-item>
    <q-expansion-item v-if="manageSiteFiltered.length > 0" group="menuExpansions" class="q-mx-md">
      <template slot="header">
        <q-item-section avatar class="side-menu-avatar">
          <q-icon name="language" />
        </q-item-section>
        <q-item-section>Manage Platform</q-item-section>
      </template>
      <div>
        <q-item v-for="(item, i) in manageSiteFiltered" item :key="i" :to="item.link" tag="a" exact :inset-level=".3">
          <q-item-section avatar class="side-menu-avatar">
            <q-icon :name="item.icon" />
          </q-item-section>
          <q-item-section>{{item.title}}</q-item-section>
        </q-item>
      </div>
    </q-expansion-item>
    <q-expansion-item v-if="$isAllowed(['||','master-admin'], true)" group="menuExpansions" class="q-mx-md">
      <template slot="header">
        <q-item-section avatar class="side-menu-avatar">
          <q-icon name="verified_user" />
        </q-item-section>
        <q-item-section>Admin</q-item-section>
      </template>
      <div>
        <q-item v-if="$isAllowed(['||','master-admin'], true)" class="q-mx-md" :to="{name: 'customers'}" tag="a" exact>
          <q-item-section avatar class="side-menu-avatar">
            <q-icon name="person"/>
          </q-item-section>
          <q-item-section>Manage Customers</q-item-section>
        </q-item>
        <q-item v-if="$isAllowed(['||','master-admin'], true)" class="q-mx-md" :to="{name: 'manageReconnaissance'}" tag="a" exact>
          <q-item-section avatar class="side-menu-avatar">
            <q-icon name="build"/>
          </q-item-section>
          <q-item-section>Manage Reconnaissance</q-item-section>
        </q-item>
        <q-item v-if="$isAllowed(['||','master-admin'], true)" class="q-mx-md" :to="{name: 'modulePreview'}" tag="a" exact>
          <q-item-section avatar class="side-menu-avatar">
            <q-icon name="visibility"/>
          </q-item-section>
          <q-item-section>Module Preview</q-item-section>
        </q-item>
      </div>
    </q-expansion-item>
    <q-item class="q-mx-md" :to="{name: 'logout'}" tag="a" exact>
      <q-item-section avatar class="side-menu-avatar">
        <q-icon name="exit_to_app"/>
      </q-item-section>
      <q-item-section>Logout</q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  data () {
    return {
      mainItems: [
        {
          title: 'Home',
          icon: 'home',
          link: { name: 'homePage' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'Required Training',
          icon: 'school',
          link: { name: 'myTraining' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'Browse Modules',
          icon: 'explore',
          link: { name: 'browse' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'Bookmarks',
          icon: 'bookmarks',
          link: { name: 'bookmarks' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'History',
          icon: 'history',
          link: { name: 'history' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'Messages',
          icon: 'chat',
          link: { name: 'shoutOuts' },
          canShow: () => { return this.$store.getters['auth/enableMessages'] },
          perms: []
        },
        {
          title: 'Leaderboards',
          icon: 'star',
          link: { name: 'leaderboards' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'Reports',
          icon: 'bar_chart',
          link: { name: 'reports' },
          canShow: () => { return this.$isAllowed(['access-reports']) },
          perms: ['access-reports']
        },
        {
          title: 'Profile',
          icon: 'person',
          link: { name: 'profile' },
          canShow: () => { return true },
          perms: []
        },
        {
          title: 'Submitted Content',
          icon: 'videocam',
          link: { name: 'submittedContent' },
          canShow: () => { return this.$store.getters['auth/enableSubmittedContent'] },
          perms: []
        }
      ],
      manageSite: [
        {
          title: 'Site',
          icon: 'computer',
          link: { name: 'manageSiteSite' },
          canShow: () => { return this.$isAllowed(['manage-site']) },
          perms: ['manage-site']
        },
        {
          title: 'Categories',
          icon: 'category',
          link: { name: 'manageSiteCategories' },
          canShow: () => { return this.$isAllowed(['manage-categories']) },
          perms: ['manage-categories']
        },
        {
          title: 'Modules',
          icon: 'view_module',
          link: { name: 'manageModules' },
          canShow: () => { return this.$isAllowed(['manage-modules']) },
          perms: ['manage-modules']
        },
        {
          title: 'Roles',
          icon: 'supervisor_account',
          link: { name: 'manageSiteUser' },
          canShow: () => { return this.$isAllowed(['manage-roles']) },
          perms: ['manage-roles']
        },
        {
          title: 'Users',
          icon: 'group',
          link: { name: 'manageUsers' },
          canShow: () => { return this.$isAllowed(['manage-users']) },
          perms: ['manage-users']
        },
        {
          title: 'Review Messages',
          icon: 'message',
          link: { name: 'manageMessages' },
          canShow: () => { return this.$isAllowed(['manage-users']) },
          perms: ['manage-users']
        },
        {
          title: 'Assign Training',
          icon: 'assignment_ind',
          link: { name: 'managedAssigned' },
          canShow: () => { return this.$isAllowed(['manage-assigned-training']) },
          perms: ['manage-assigned-training']
        },
        {
          title: 'Unlock Quizzes',
          icon: 'lock_open',
          link: { name: 'unlockQuizzes' },
          canShow: () => { return this.$store.getters['auth/user'].is_advocate },
          perms: 'advocate'
        },
        {
          title: 'Company',
          icon: 'business',
          link: { name: 'manageSiteCompany' },
          canShow: () => { return this.$isAllowed(['manage-company']) },
          perms: ['manage-company']
        },
        {
          title: 'IP Access',
          icon: 'gps_fixed',
          link: { name: 'manageSiteIPs' },
          canShow: () => { return this.$isAllowed(['manage-ip-access']) },
          perms: ['manage-ip-access']
        },
        {
          title: 'Banners',
          icon: 'image',
          link: { name: 'manageSiteBanners' },
          canShow: () => { return this.$isAllowed(['manage-banners']) },
          perms: ['manage-banners']
        },
        {
          title: 'News',
          icon: 'web',
          link: { name: 'manageSiteNews' },
          canShow: () => { return this.$isAllowed(['manage-news']) },
          perms: ['manage-news']
        },
        {
          title: 'Announcements',
          icon: 'record_voice_over',
          link: { name: 'manageSiteAnnouncements' },
          canShow: () => { return this.$isAllowed(['manage-announcements']) },
          perms: ['manage-announcements']
        },
        {
          title: 'Links',
          icon: 'link',
          link: { name: 'manageSiteLinks' },
          canShow: () => { return this.$isAllowed(['manage-links']) },
          perms: ['manage-links']
        },
        {
          title: 'File Uploads',
          icon: 'cloud_upload',
          link: { name: 'manageSiteFileUploads' },
          canShow: () => { return this.$isAllowed(['manage-uploads']) },
          perms: ['manage-uploads']
        },
        {
          title: 'Global Vendor List',
          icon: 'storefront',
          link: { name: 'manageVendors' },
          canShow: () => { return this.$isAllowed(['manage-modules']) },
          perms: ['manage-modules']
        },
        {
          title: 'Custom Themes',
          icon: 'color_lens',
          link: { name: 'manageSiteThemes' },
          canShow: () => { return this.$isAllowed(['manage-custom-themes']) },
          perms: ['manage-custom-themes']
        },
        {
          title: 'Bugs & Features',
          icon: 'bug_report',
          link: { name: 'manageSiteBugs' },
          canShow: () => { return this.$isAllowed(['manage-bug-requests']) },
          perms: ['manage-bug-requests']
        }
      ]
    }
  },
  computed: {
    mainItemsFiltered () {
      return this.mainItems.filter(item => item.canShow())
    },
    manageSiteFiltered () {
      return this.manageSite.filter(item => item.canShow() && this.mediaCanBeDisplayed(item))
    }
  },
  methods: {
    mediaCanBeDisplayed (item) {
      if (['News', 'Announcements', 'Links', 'File Uploads', 'Banners'].includes(item.title)) {
        return this.$isAllowed(['||', 'master-admin', 'site-admin'], true) || this.$store.getters['auth/homePageFilter']
      }
      return true
    }
  }
}
</script>

<style>

</style>
