<template>
  <q-drawer :value="opened" overlay bordered side="right" :width="574" :breakpoint="900" content-class="bg-lmsBackground primaryText-text" @hide="$emit('close')">
    <div class="q-pa-md">
      <div class="row justify-between">
        <div class="text-h4">Search Results</div>
        <q-btn dense flat icon="close" style="z-index: 2" @click="$emit('close')">
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </div>
      <div v-if="allowedModules">
        <modules-grid :modules="allowedModules" :bookmarkable="false"></modules-grid>
      </div>
    </div>
  </q-drawer>
</template>

<script>
import ModulesGrid from 'components/ModulesGrid'

export default {
  components: { ModulesGrid },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    searchResults: null
  },
  computed: {
    allowedModules () {
      if (!this.searchResults) return
      return this.searchResults.filter(module => this.isAllowedToView(module))
    }
  },
  methods: {
    isAllowedToView (module) {
      if (module.browsable === 'true') {
        return true
      } else if (module.browsable === 'false') {
        return false
      } else if (module.browsable === 'restricted') {
        return (this.$store.getters['auth/userDepartmentUids'].some(departmentUid => module.restrictions.departments.includes(departmentUid)) ||
            this.$store.getters['auth/user'].roles.map(role => role.uid).some(roleUid => module.restrictions.roles.includes(roleUid)) ||
            module.restrictions.locations.includes(this.$store.getters['auth/customer']))
      }
    }
  }
}
</script>
