<template>
  <q-btn round flat icon="notifications" @click="markAllAsRead">
    <q-badge color="black" floating v-if="this.$store.getters['notifications/unread'].length">{{ this.$store.getters['notifications/unread'].length }}</q-badge>
    <q-menu max-width="400px" auto-close class="bg-lmsBackground">
      <q-list style="min-width: 400px" class="bg-lmsBackground primaryText-text">
        <notification-item v-for="notification in notifications" :key="notification.uid" :notification="notification" @highlightSection="highlightSection"/>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import NotificationItem from 'components/NotificationItem'

export default {
  name: 'Notifications',
  components: { NotificationItem },
  data () {
    return {
      showHighlight: false,
      sectionToHighlight: null,
      fetchNotifications: null
    }
  },
  computed: {
    notifications () {
      return this.$store.getters['notifications/data']
    }
  },
  watch: {
    '$route' (to, from) {
      setTimeout(() => {
        if (to.name === 'homePage' && this.showHighlight) {
          this.doHighlight()
        }
      }, 1000)
    }
  },
  methods: {
    markAllAsRead () {
      if (this.notifications.some(notification => !notification.seen)) {
        this.$store.dispatch('notifications/markAllAsRead')
      }
    },
    highlightSection (section) {
      this.showHighlight = true
      this.sectionToHighlight = section
      if (this.$route.name === 'homePage') {
        this.doHighlight()
      }
    },
    doHighlight () {
      this.showHighlight = false
      let section = document.getElementById(`${this.sectionToHighlight}Section`)

      if (section) {
        section.style.backgroundColor = '#E1E1E1'

        setTimeout(() => {
          section.style.backgroundColor = null
        }, 4000)
      }
    },
    startFetchNotifications () {
      this.fetchNotifications = setInterval(async () => {
        try {
          await this.$store.dispatch('notifications/fetch')
        } catch (e) {
          return e
        }
      }, 60000)
    }
  },
  created () {
    this.$store.dispatch('notifications/fetch')
    this.startFetchNotifications()
  },
  beforeDestroy () {
    clearInterval(this.fetchNotifications)
  }
}
</script>
