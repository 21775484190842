<template>
  <div>
    <IsLoggedIn v-if="authenticated && !$newVersionExists" />
    <Login v-else />

    <q-dialog v-model="loggingIn" persistent maximized transition-show="slide-up" transition-hide="slide-down">
      <q-card class="bg-white text-black">
        <q-card-section class="row full-width justify-center q-mt-xl">
          <div class="text-h3">Welcome to LMS!</div>
        </q-card-section>
        <q-card-section class="row full-width justify-center q-mb-xl">
          <div class="text-subtitle1">We're getting things ready for you</div>
        </q-card-section>
        <q-card-section class="row full-width justify-center q-my-xl">
          <q-spinner size="100px" color="black" />
        </q-card-section>
        <q-card-section class="row full-width justify-center">
          <div class="text-subtitle1">{{ currentFetch }}</div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Login from 'layouts/login'
import IsLoggedIn from 'layouts/isLoggedIn'

export default {
  components: { Login, IsLoggedIn },
  name: 'Layout',
  data () {
    return {
      currentFetch: 'Logging You In',
      whatToFetch: ['User Settings', 'Company Settings', 'Training', 'News', 'Announcements'],
      fetchCycle: null
    }
  },
  computed: {
    loggingIn () {
      return this.$store.getters['auth/isLoggingIn']
    },
    authenticated () {
      return this.$store.getters['auth/authenticated']
    }
  },
  watch: {
    loggingIn (val) {
      if (!val) {
        clearInterval(this.fetchCycle)
        this.currentFetch = 'Logging You In'
      }
    },
    authenticated (val) {
      let i = 0
      if (val) {
        this.fetchCycle = setInterval(() => {
          this.currentFetch = this.whatToFetch[i] ? `Fetching ${this.whatToFetch[i]}` : 'Finishing Up'

          i++
        }, 2000)
      }
    }
  },
  created () {
    this.$store.dispatch('auth/loggingIn', false)
  }
}
</script>
