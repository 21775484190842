<template>
  <q-layout ref="layout" view="hhh Lpr fFf" class="bg-lmsBackground primaryText-text">
    <q-header class="q-pa-xs bg-lmsHeader">
      <q-toolbar class="toolbar row justify-between items-center">
        <div class="col-xs-2 col-sm-1 col-md-1 lt-md">
          <div>
            <q-btn flat round dense @click.native="showLeft = !showLeft" icon="menu" />
          </div>
        </div>
        <div class="logo col-xs-8 col-sm-10 col-md-4 col-lg-4 row">
          <q-btn flat @click.native="$router.push({name: 'homePage'}, () => {})">
            <img id="companyLogo" :src="($store.getters['auth/companyLogo'] ? $store.getters['auth/companyLogo'] : '/statics/general/midstates-logo-red-white.png')" class="img-height-xl max-image-size">
          </q-btn>
        </div>

        <div class="q-gutter-md row col justify-end">
          <q-input class="gt-sm" type="search" bg-color="lmsSearchBar" color="white" standout dense rounded @click="$store.dispatch('auth/setSearchModal', true)" @keyup="searchModules" v-model="search" label="Search Modules">
            <template v-slot:prepend>
              <q-icon name="search" />
            </template>
          </q-input>
          <Notifications />
          <q-btn round class="gt-sm">
            <q-avatar size="42px">
              <q-img id="profileImage" :src="this.$store.getters['auth/user'].avatar || $consts.DEFAULT_AVATAR" style="height: 42px; width: 42px; border-radius: 25px;"/>
            </q-avatar>
            <q-menu max-width="300px">
              <q-list padding style="min-width: 200px" class="bg-lmsBackground primaryText-text">
                <q-item clickable v-close-popup @click="$router.push({name: 'profile'}).catch(() => {})">
                  <q-item-section>Profile</q-item-section>
                </q-item>
                <q-item v-if="$store.getters['auth/enableMessages']" clickable v-close-popup @click="$router.push({name: 'shoutOuts'}).catch(() => {})" tag="a" exact>
                  <q-item-section>Inbox</q-item-section>
                </q-item>
                <q-item clickable v-close-popup :to="{name: 'logout'}" tag="a" exact>
                  <q-item-section>Logout</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer side="left" v-model="showLeft" class="side-menu" content-class="bg-lmsSidebar primaryText-text">
      <SideMenu />
    </q-drawer>

    <search-drawer :opened="showSearchDrawer" :searchResults="$store.getters['auth/searchResults']" v-on:close="$store.dispatch('auth/setSearchModal', false)" />

    <q-page-container class="bg-lmsBackground">
      <router-view />
    </q-page-container>

    <q-dialog v-model="previewingTheme" seamless position="bottom">
      <q-card style="width: 550px">
        <q-card-section class="row items-center no-wrap justify-between">
            <div class="text-weight-bold">You are currently previewing a theme</div>
            <q-btn flat label="Go Back and Save" @click="$router.push({ name: 'manageSiteThemes' })"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import SearchDrawer from 'components/SearchDrawer'
import Notifications from 'components/Notifications'
import SideMenu from 'layouts/SideMenu'

export default {
  components: { SearchDrawer, Notifications, SideMenu },
  data () {
    return {
      showLeft: true,
      search: ''
    }
  },
  watch: {
    showSearchDrawer: function (newValue) {
      if (!newValue) {
        this.search = ''
        this.$store.dispatch('auth/setSearchResults', null)
      }
    },
    isAppIdle: function (newValue) {
      if (newValue) {
        this.$q.dialog({
          title: 'Inactivity',
          message: `You've been inactive for a while. Unless you hit OK, you'll be logged out.`,
          persistent: true
        })
        setTimeout(() => {
          if (this.isAppIdle) {
            this.$router.push({ name: 'logout' })
          }
        }, 60000)
      }
    },
    currentTheme: function (newVal) {
      this.$setTheme(newVal)
    }
  },
  computed: {
    showSearchDrawer () {
      return this.$store.getters['auth/showSearch']
    },
    previewingTheme: {
      get: function () {
        return this.$store.getters['themes/isPreviewing'] && this.$route.name !== 'manageSiteThemes'
      },
      set: function (nothing) {
        return this.$store.getters['themes/isPreviewing'] && this.$route.name !== 'manageSiteThemes'
      }
    },
    currentTheme () {
      return this.$store.getters['auth/currentTheme']
    }
  },
  methods: {
    searchModules () {
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: this.search,
          params: {
            filters: 'customer_uid:' + this.$store.getters['auth/company'].uid
          }
        }
      ], (err, content) => {
        if (err) throw err
        let tCombined = []
        _.forEach(content.results, (result) => {
          _.forEach(result.hits, (hit) => {
            if (result.index === this.$consts.MODULES_INDEX) {
              hit.type = 'module'
            } else {
              hit.type = 'course'
            }
            tCombined.push(hit)
          })
        })
        this.$store.dispatch('auth/setSearchResults', tCombined)
      })
    }
  },
  created () {
    this.$setTheme(this.currentTheme)
    document.body.classList.add('bg-lmsBackground')
  }
}
</script>
